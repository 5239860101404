import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

const NotFound = () => {
  const router = useRouter();
  return (
    <>
      <Head>
        <meta
          name="keywords"
          content="kerja,kerjago,kerjago,cari kerja,part time, go kerja"
        />
      </Head>
      <div>
        <div className="flex justify-center items-center h-screen w-screen flex-col">
          <div className="flex flex-col mb-8 text-center">
            <div className="font-bold text-7xl">404</div>
            <div className="text-xl">Page Not Found</div>
          </div>
          <button
            type="button"
            onClick={() => {
              router.push('/');
            }}
            className="underline"
          >
            back to home
          </button>
        </div>
      </div>
    </>
  );
};

export default NotFound;
